import React, { useState } from 'react';
import { CheckResult } from '@backstage/plugin-tech-insights-common';
import { Collapse } from '@material-ui/core';
import List from '@mui/material/List';
import {
  getServiceMaturityCheckValue,
  ServiceMaturityFactValue,
} from 'backstage-plugin-service-maturity-common';
import { ServiceMaturityCategoryListSubheader } from '../ServiceMaturityCategoryListSubheader';
import { ServiceMaturityCategoryListItem } from '../ServiceMaturityCategoryListItem';

export const ServiceMaturityCategoryList = ({
  category,
  results,
}: {
  category: string;
  results: CheckResult[];
}) => {
  const isPassed = getServiceMaturityCheckValue(results);
  const [isOpen, setIsOpen] = useState(!isPassed);
  const onSubheaderClick = () => setIsOpen(!isOpen);

  // sort results with disabled checks last
  const sortedResults = [...results].sort((a, _) => {
    const isDisabled = (value: ServiceMaturityFactValue) => value.isDisabled;
    if (
      Object.values(a.facts)
        .map(fact => fact.value as unknown as ServiceMaturityFactValue)
        .some(isDisabled)
    ) {
      return 1;
    }

    return -1;
  });

  return (
    <List
      subheader={
        <ServiceMaturityCategoryListSubheader
          category={category}
          isOpen={isOpen}
          onClick={onSubheaderClick}
          results={results}
        />
      }
    >
      <Collapse key={category} in={isOpen}>
        {sortedResults.map(result => (
          <ServiceMaturityCategoryListItem
            key={result.check.id}
            result={result}
          />
        ))}
      </Collapse>
    </List>
  );
};
