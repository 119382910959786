import {
  BackstageTheme,
  createTheme,
  genPageTheme,
  lightTheme,
  shapes,
} from '@backstage/theme';

export const productboardLightTheme: BackstageTheme = {
  ...createTheme({
    palette: {
      ...lightTheme.palette,
      primary: {
        main: '#2693FF',
        contrastText: '#F2F5F7',
      },
      secondary: {
        main: '#202428',
      },
      error: {
        main: '#FF1F1F',
        contrastText: '#F2F5F7',
      },
      warning: {
        main: '#FA7414',
        contrastText: '#F2F5F7',
      },
      info: {
        main: '#0079F2',
        contrastText: '#F2F5F7',
      },
      success: {
        main: '#5EC200',
      },
      background: {
        default: '#F2F5F7',
        paper: '#FFFFFF',
      },
      border: '#F2F5F7',
      banner: {
        info: '#0079F2',
        error: '#FF1F1F',
        text: '#343b58',
        link: '#565a6e',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: '#F2F5F7',
        indicator: '#939DA7',
        color: '#939DA7',
        selectedColor: '#328DFC',
        navItem: {
          hoverBackground: '#F8FAFB',
        },
        submenu: {
          background: '#F2F5F7',
        },
      },
      tabbar: {
        indicator: '#F2F5F7',
      },
    },
    fontFamily: 'proxima-nova,Arial CE,Arial,sans-serif',
    defaultPageTheme: 'home',
    pageTheme: {
      home: genPageTheme({
        colors: ['#CCE4FC'],
        shape: shapes.square,
        options: { fontColor: '#202428' },
      }),
    },
  }),
  overrides: {
    ...lightTheme.overrides,
    // These are your custom overrides, either to `material-ui` or Backstage components.
    ...{
      MuiTableCell: {
        root: {
          backgroundColor: '#FFFFFF',
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          color: '#939DA7',
          '&$checked': {
            color: '#2693FF',
          },
        },
      },
      MuiTypography: {
        h6: {
          color: '#202428',
        },
      },
    },
    ...{
      BackstageHeader: {
        header: {
          boxShadow: 'none',
          marginTop: '64px',
        },
      },
      BackstageHeaderLabel: {
        label: {
          color: '#202428',
          padding: '0 20px',
        },
        value: {
          color: '#202428',
          padding: '0 20px',
        },
      },
      BackstageSidebarDivider: {
        root: {
          background: '#F2F5F7',
        },
      },
      BackstageItemCardHeader: {
        root: {
          color: '#202428',
        },
      },
      BackstageTable: {
        root: {
          background: '#FFFFFF',
        },
      },
    },
    ...{
      CatalogReactUserListPicker: {
        root: {
          backgroundColor: '#E1E6EA',
        },
      },
    },
  },
};
