import { makeStyles, Box } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import React from 'react';
import { Link } from '@backstage/core-components';
import { IconComponent } from '@backstage/core-plugin-api';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  svgIcon: {
    display: 'inline-block',
    lineHeight: 0,
    marginRight: '8px',
    '& svg': {
      display: 'inline-block',
      fontSize: 'inherit',
    },
  },
});

export function IconLink(props: {
  href: string;
  text?: string;
  Icon?: IconComponent;
}) {
  const { href, text, Icon } = props;
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.svgIcon}>
        {Icon ? <Icon /> : <LanguageIcon />}
      </Box>
      <Box flexGrow="1">
        <Link to={href} target="_blank" rel="noopener">
          {text || href}
        </Link>
      </Box>
    </Box>
  );
}
