import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const serviceMaturityPlugin = createPlugin({
  id: 'service-maturity',
  routes: {
    root: rootRouteRef,
  },
});

export const ServiceMaturityTab = serviceMaturityPlugin.provide(
  createRoutableExtension({
    name: 'ServiceMaturityTab',
    component: () =>
      import('./components/ServiceMaturityTab').then(m => m.ServiceMaturityTab),
    mountPoint: rootRouteRef,
  }),
);
