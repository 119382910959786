import '@backstage/cli/asset-types';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import { isProduction, getBuildInfo } from './utils';

const { commit } = getBuildInfo();

if (isProduction()) {
  Sentry.init({
    dsn: 'https://fed2f729b162469e8d1513e5d53f6ee1@o503008.ingest.sentry.io/6434264',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // Let's revisit this once it takes off (jukben)
    tracesSampleRate: 1.0,
    release: commit,
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
