import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  makeStyles,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Divider,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  SearchContextProvider,
  useSearch,
} from '@backstage/plugin-search-react';
import { useNavigate } from 'react-router';
import useDebounce from 'react-use/lib/useDebounce';
import { SearchResultListItem } from './SearchResultItem';
import { Stack } from '@mui/system';
import { darkThemeColors } from '../../themes/dark';
import { BackstageTheme } from '@backstage/theme';

type TechDocsDoc = {
  namespace: string;
  kind: string;
  name: string;
  path: string;
  location: string;
  title: string;
};

type TechDocsSearchResult = {
  type: string;
  document: TechDocsDoc;
};

const useStyles = makeStyles<BackstageTheme>(theme => ({
  root: {
    width: '100%',
    maxWidth: '800px',
  },
  textField: {
    backgroundColor:
      theme.palette.type === 'dark' ? darkThemeColors.bg5 : '#F8FAFB',
    borderRadius: '4px',
  },
}));

const SearchAutocomplete = () => {
  const debounceTime = 150;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const {
    term,
    setTerm,
    result: { loading, value: searchVal },
  } = useSearch();
  const classes = useStyles();
  const [options, setOptions] = useState<any[]>([]);
  const [value, setValue] = useState<string>(term);

  useEffect(() => {
    let mounted = true;

    if (mounted && searchVal) {
      // TODO: Change this into getting only subset of search results from the BE in the first place
      // once pagination is implemented for search engines
      // See: https://github.com/backstage/backstage/issues/6062
      if (value.length < 2) {
        setOptions([]);
      } else {
        // iterate over search results and sort entity results to the top
        const searchResults = searchVal.results;

        // Prefer non-docs results at the top
        searchResults.sort((a, b) => {
          if (a.type === 'software-catalog' && b.type !== 'software-catalog') {
            return -1;
          }

          return 1;
        });

        setOptions(searchResults);
      }
    }
    return () => {
      mounted = false;
    };
  }, [loading, searchVal, value]);

  useDebounce(() => setTerm(value), debounceTime, [value]);

  const handleQuery = (e: ChangeEvent<HTMLInputElement>) => {
    if (!open) {
      setOpen(true);
    }
    setValue(e.target.value);
  };

  const handleSelection = (_: any, selection: TechDocsSearchResult | null) => {
    if (selection?.document) {
      const { location } = selection.document;
      navigate(location);
    }
  };

  return (
    <Autocomplete
      classes={{ root: classes.root }}
      data-testid="homepage-search-bar"
      size="small"
      open={open}
      getOptionLabel={() => ''}
      filterOptions={x => {
        return x; // This is needed to get renderOption to be called after options change. Bug in material-ui?
      }}
      onClose={() => {
        setOpen(false);
      }}
      onFocus={() => {
        if (value.length > 0) {
          setOpen(true);
        }
      }}
      onChange={handleSelection}
      onBlur={() => {
        setTerm('');
      }}
      blurOnSelect
      popupIcon={null}
      noOptionsText="No results found"
      value={null}
      options={options}
      renderOption={({ type, document, highlight }) => {
        const isDocs = document.location.startsWith('/docs');

        const specType = isDocs ? 'Documentation' : document.kind;
        switch (type) {
          default:
            return (
              <Stack width="100%">
                <SearchResultListItem
                  key={document.location}
                  result={document}
                  highlight={highlight}
                  type={specType}
                />
                <Divider />
              </Stack>
            );
        }
      }}
      loading={loading}
      renderInput={params => (
        <TextField
          {...params}
          data-testid="homepage-search-bar-input"
          variant="standard"
          fullWidth
          placeholder="Search in Developer Portal"
          value={value}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onChange={handleQuery}
          className={classes.textField}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: {
              height: '36px',
            },
            startAdornment: (
              <InputAdornment position="start">
                <IconButton aria-label="Query" disabled>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export const SearchBar = () => {
  return (
    <SearchContextProvider>
      <SearchAutocomplete />
    </SearchContextProvider>
  );
};
