import React from 'react';
import {
  Content,
  ContentHeader,
  PageWithHeader,
  SupportButton,
  TableColumn,
} from '@backstage/core-components';
import { DefaultApiExplorerPageProps } from '@backstage/plugin-api-docs';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';
import {
  CatalogFilterLayout,
  EntityKindPicker,
  EntityListProvider,
  EntityOwnerPicker,
  EntityTypePicker,
  UserListPicker,
} from '@backstage/plugin-catalog-react';

export const ApiExplorerPage = ({
  actions,
  initiallySelectedFilter = 'all',
}: DefaultApiExplorerPageProps) => {
  const columns: TableColumn<CatalogTableRow>[] = [
    CatalogTable.columns.createTitleColumn({ hidden: true }),
    CatalogTable.columns.createNameColumn({ defaultKind: 'API' }),
    CatalogTable.columns.createMetadataDescriptionColumn(),
    CatalogTable.columns.createOwnerColumn(),
    CatalogTable.columns.createSpecTypeColumn(),
  ];

  for (const column of columns) {
    column.width = column.width ?? 'auto';
  }

  return (
    <PageWithHeader
      themeId="home"
      title="APIs"
      subtitle="Productboard API Explorer"
      pageTitleOverride="APIs"
    >
      <EntityListProvider>
        <Content>
          <ContentHeader title="">
            <SupportButton>Reach to us:</SupportButton>
          </ContentHeader>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <EntityKindPicker initialFilter="api" hidden />
              <EntityTypePicker />
              <UserListPicker initialFilter={initiallySelectedFilter} />
              <EntityOwnerPicker />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <CatalogTable columns={columns} actions={actions} />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </Content>
      </EntityListProvider>
    </PageWithHeader>
  );
};
