import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { useTheme } from '@material-ui/core';
import { Link, TableColumn } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { CatalogTableRow } from '@backstage/plugin-catalog';
import { techInsightsApiRef } from '@backstage/plugin-tech-insights';
import { Entity, getCompoundEntityRef } from '@backstage/catalog-model';
import { getServiceMaturityCheckValue } from 'backstage-plugin-service-maturity-common';
import { isServiceMaturityAvailable } from '../../helpers';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import WarningIcon from '@mui/icons-material/Warning';
import HelpIcon from '@mui/icons-material/Help';

type ColumnProps = {
  entity: Entity;
};

const ServiceMaturityColumn = ({ entity }: ColumnProps) => {
  const theme = useTheme();
  const api = useApi(techInsightsApiRef);
  const {
    value: checkResults,
    loading,
    error,
  } = useAsync(async () => await api.runChecks(getCompoundEntityRef(entity)));

  if (!isServiceMaturityAvailable(entity)) {
    return (
      <Tooltip title="Not applicable">
        <RemoveCircleIcon style={{ color: theme.palette.text.hint }} />
      </Tooltip>
    );
  }

  if (loading) {
    return <CircularProgress size={24} />;
  } else if (error) {
    return (
      <Tooltip title={`Error: ${error}`}>
        <ErrorIcon color="error" />
      </Tooltip>
    );
  }

  if (!checkResults || checkResults.length === 0) {
    return (
      <Tooltip title="Unknown: empty check results">
        <HelpIcon color="info" />
      </Tooltip>
    );
  }

  const status = getServiceMaturityCheckValue(checkResults);

  return (
    <Link
      to={`/catalog/${entity.metadata.namespace ?? 'default'}/${entity.kind}/${
        entity.metadata.name
      }/service-maturity`}
    >
      {status ? (
        <Tooltip title="Passed">
          <CheckCircleIcon color="success" />
        </Tooltip>
      ) : (
        <Tooltip title="Failed">
          <WarningIcon color="warning" />
        </Tooltip>
      )}
    </Link>
  );
};

export const createServiceMaturityColumn = (): TableColumn<CatalogTableRow> => {
  return {
    title: 'Service Maturity',
    field: 'serviceMaturity',
    width: '180px',
    sorting: false,
    render: (row: CatalogTableRow) => {
      return (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <ServiceMaturityColumn entity={row.entity} />
        </div>
      );
    },
  };
};
