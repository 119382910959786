import {
  ScmAuth,
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
  analyticsApiRef,
  storageApiRef,
  errorApiRef,
} from '@backstage/core-plugin-api';
import { GoogleAnalytics4 } from '@backstage/plugin-analytics-module-ga4';
import { UserSettingsStorage } from '@backstage/plugin-user-settings';

import {
  scaffolderApiRef,
  ScaffolderClient,
} from '@backstage/plugin-scaffolder';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: storageApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      errorApi: errorApiRef,
      fetchApi: fetchApiRef,
      identityApi: identityApiRef,
    },
    factory: deps => UserSettingsStorage.create(deps),
  }),
  createApiFactory({
    api: scaffolderApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      fetchApi: fetchApiRef,
      scmIntegrationsApi: scmIntegrationsApiRef,
    },
    factory: ({ discoveryApi, fetchApi, scmIntegrationsApi }) =>
      new ScaffolderClient({
        discoveryApi,
        fetchApi,
        scmIntegrationsApi,
        // use long polling instead of an eventsource
        useLongPollingLogs: true,
      }),
  }),
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) =>
      GoogleAnalytics4.fromConfig(configApi, {
        identityApi,
      }),
  }),
];
