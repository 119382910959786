import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import MenuItem from '@mui/material/MenuItem';

import { BackstageTheme } from '@backstage/theme';
import { ServiceMaturityFact } from 'backstage-plugin-service-maturity-common';

const useStyles = makeStyles<BackstageTheme>((theme: BackstageTheme) => ({
  dialog: {
    '& .MuiDialogTitle-root': {
      color: theme.palette.text.primary,
      paddingLeft: '0',
    },
    '& .MuiDialog-paper': {
      borderRadius: 6,
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiDialogContentText-root': {
      color: theme.palette.text.secondary,
      paddingBottom: '16px',
    },
  },
  formControl: {
    '& .MuiFormControl-root': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.text.hint,
    },
    '& .MuiSelect-select': {
      color: theme.palette.text.primary,
    },
  },
}));

export function ManualFactDialog({
  open,
  fact,
  onClose,
  onSubmit,
}: {
  open: boolean;
  fact: ServiceMaturityFact;
  onClose: () => void;
  onSubmit: (value: string) => void;
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [value, setValue] = useState<string>('');

  const onSubmitHandler = () => {
    onSubmit(value);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };

  return (
    <Dialog
      className={classes.dialog}
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <DialogContent>
        <DialogTitle>Enter the result</DialogTitle>
        <DialogContentText>{fact.description}</DialogContentText>
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel
            className={classes.inputLabel}
            id="manual-facts-dialog-select-label"
          >
            Select check result
          </InputLabel>
          <Select
            labelId="manual-fact-dialog-result-label"
            id="manual-fact-dialog-result"
            value={value}
            onChange={handleChange}
            MenuProps={{
              sx: {
                '& .MuiMenu-paper': {
                  backgroundColor: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                },
                '& .MuiMenuItem-root:hover': {
                  backgroundColor: theme.palette.background.default,
                },
              },
            }}
          >
            <MenuItem value="false">Fail</MenuItem>
            <MenuItem value="true">Pass</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmitHandler}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
