import React from 'react';

import { Box, Grid, Container } from '@material-ui/core';

import { PlatformHighlightsCard } from './PlatformHighlightsCard';
import { OwnedEntitiesCard } from './OwnedEntitiesCard';

export const HomePage = () => {
  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          width: '100%',
          height: '100%',
          padding: '88px 0 20px 0',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <OwnedEntitiesCard />
          </Grid>
          <Grid item xs={12} md={8}>
            <PlatformHighlightsCard />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
