import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { Progress } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { techInsightsApiRef } from '@backstage/plugin-tech-insights';
import { useEntity } from '@backstage/plugin-catalog-react';
import { getCompoundEntityRef } from '@backstage/catalog-model';
import { ServiceMaturityCardContent } from '../ServiceMaturityCardContent';

import Alert from '@material-ui/lab/Alert';

export const ServiceMaturityCard = () => {
  const api = useApi(techInsightsApiRef);
  const { namespace, kind, name } = getCompoundEntityRef(useEntity().entity);
  const {
    value: checkResults,
    loading,
    error,
  } = useAsync(async () => await api.runChecks({ namespace, kind, name }));

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  if (!checkResults || checkResults.length === 0) {
    return (
      <Alert severity="info">No checks found. Please, try again later.</Alert>
    );
  }

  return <ServiceMaturityCardContent checkResults={checkResults} />;
};
