import React from 'react';
import {
  OnCallList,
  AlertsList,
  Layout,
} from '@k-phoen/backstage-plugin-opsgenie';

export const OpsgeniePage = (): JSX.Element => {
  return (
    <Layout>
      <Layout.Route path="who-is-on-call" title="Who is on-call">
        <OnCallList
          cardsPerPage={30}
          responderFormatter={(responder, schedule) => {
            return `${responder.name} for "${schedule.name}"`;
          }}
        />
      </Layout.Route>
      <Layout.Route path="alerts" title="Alerts">
        <AlertsList />
      </Layout.Route>
    </Layout>
  );
};
