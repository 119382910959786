import React from 'react';
import {
  Content,
  ContentHeader,
  CreateButton,
  PageWithHeader,
  SupportButton,
  TableColumn,
} from '@backstage/core-components';
import {
  CatalogKindHeader,
  CatalogTable,
  CatalogTableRow,
  DefaultCatalogPageProps,
} from '@backstage/plugin-catalog';
import {
  CatalogFilterLayout,
  EntityLifecyclePicker,
  EntityListProvider,
  EntityOwnerPicker,
  EntityTypePicker,
  UserListPicker,
  EntityRefLink,
  useEntityList,
} from '@backstage/plugin-catalog-react';
import { createServiceMaturityColumn } from 'backstage-plugin-service-maturity';

const CatalogPageContents = ({
  initiallySelectedFilter,
  actions,
}: DefaultCatalogPageProps) => {
  const {
    filters: { kind: kindFilter },
  } = useEntityList();
  const isComponent = kindFilter?.value === 'component';
  const isResource = kindFilter?.value === 'resource';
  const isSystem = kindFilter?.value === 'system';
  const isApi = kindFilter?.value === 'api';
  const isUser = kindFilter?.value === 'user';

  const nameColumn: TableColumn<CatalogTableRow> =
    CatalogTable.columns.createNameColumn();
  nameColumn.render = ({ entity }) => (
    <EntityRefLink entityRef={entity} title={entity.metadata.name} />
  );

  const columns: TableColumn<CatalogTableRow>[] = [
    nameColumn,
    CatalogTable.columns.createMetadataDescriptionColumn(),
  ];

  if (!isUser && !isSystem) {
    columns.push(CatalogTable.columns.createSpecTypeColumn());
  }

  if (isResource || isSystem || isComponent || isApi) {
    columns.push(CatalogTable.columns.createOwnerColumn());
  }

  if (isComponent) {
    columns.push(createServiceMaturityColumn());
  }

  for (const column of columns) {
    column.width = column.width ?? 'auto';
  }

  return (
    <Content>
      <ContentHeader titleComponent={<CatalogKindHeader />}>
        {isComponent && (
          <CreateButton
            title="Create New Kotlin Service"
            to="/create/templates/default/kotlin-service-template"
          />
        )}
        <SupportButton>Reach out to us:</SupportButton>
      </ContentHeader>
      <CatalogFilterLayout>
        <CatalogFilterLayout.Filters>
          <EntityTypePicker />
          <UserListPicker initialFilter={initiallySelectedFilter} />
          <EntityOwnerPicker />
          <EntityLifecyclePicker />
        </CatalogFilterLayout.Filters>
        <CatalogFilterLayout.Content>
          <CatalogTable columns={columns} actions={actions} />
        </CatalogFilterLayout.Content>
      </CatalogFilterLayout>
    </Content>
  );
};

export const CustomCatalogPage = (props: DefaultCatalogPageProps) => {
  return (
    <PageWithHeader title="Productboard Catalog" themeId="home">
      <EntityListProvider>
        <CatalogPageContents {...props} />
      </EntityListProvider>
    </PageWithHeader>
  );
};
