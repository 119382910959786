import React, { ReactNode } from 'react';
import {
  Box,
  Chip,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { Link } from '@backstage/core-components';
import { useAnalytics } from '@backstage/core-plugin-api';
import {
  IndexableDocument,
  ResultHighlight,
} from '@backstage/plugin-search-common';
import { HighlightedSearchResultText } from '@backstage/plugin-search-react';

const useStyles = makeStyles({
  flexContainer: {
    flexWrap: 'wrap',
  },
  itemText: {
    width: '100%',
    wordBreak: 'break-all',
    marginBottom: '1rem',
  },
});

export interface SearchResultListItemProps {
  icon?: ReactNode;
  result: IndexableDocument;
  highlight?: ResultHighlight;
  type: string;
  rank?: number;
}

export function SearchResultListItem(props: SearchResultListItemProps) {
  const result = props.result as any;

  const classes = useStyles();
  const analytics = useAnalytics();
  const handleClick = () => {
    analytics.captureEvent('discover', result.title, {
      attributes: { to: result.location },
      value: props.rank,
    });
  };

  return (
    <Link noTrack to={result.location} onClick={handleClick}>
      <>
        {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
        <div className={classes.flexContainer}>
          <ListItemText
            className={classes.itemText}
            primaryTypographyProps={{ variant: 'h6' }}
            primary={
              props.highlight?.fields.title ? (
                <HighlightedSearchResultText
                  text={props.highlight.fields.title}
                  preTag={props.highlight.preTag}
                  postTag={props.highlight.postTag}
                />
              ) : (
                result.title
              )
            }
            secondary={
              props.highlight?.fields.text ? (
                <HighlightedSearchResultText
                  text={props.highlight.fields.text}
                  preTag={props.highlight.preTag}
                  postTag={props.highlight.postTag}
                />
              ) : (
                result.text
              )
            }
          />
          <Box>
            {result && <Chip label={`Type: ${props.type}`} size="small" />}
          </Box>
        </div>
      </>
    </Link>
  );
}
