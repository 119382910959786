import React, { useContext, useState } from 'react';
import { BackstageTheme } from '@backstage/theme';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  useApi,
  configApiRef,
  fetchApiRef,
  alertApiRef,
} from '@backstage/core-plugin-api';
import { useUserProfile } from '@backstage/plugin-user-settings';
import { stringifyEntityRef } from '@backstage/catalog-model';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { makeStyles, useTheme } from '@material-ui/core';
import {
  ManualFact,
  ServiceMaturityFact,
} from 'backstage-plugin-service-maturity-common';
import { ManualFactDialog } from '../ManualFactDialog/ManualFactDialog';
import { ServiceMaturityContext } from '../service-maturity-context';

import EditIcon from '@mui/icons-material/Edit';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import PowerOnIcon from '@mui/icons-material/Power';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const useStyles = makeStyles<BackstageTheme>(theme => ({
  menu: {
    '& .MuiPaper-root': {
      borderRadius: 6,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        fontSize: 14,
        '& .MuiSvgIcon-root': {
          fontSize: 16,
          color: theme.palette.text.hint,
          marginRight: theme.spacing(1.5),
        },
      },
    },
  },
}));

export function FactActionsMenu({ fact }: { fact: ServiceMaturityFact }) {
  const { refreshCheckResults } = useContext(ServiceMaturityContext);
  const classes = useStyles();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { postManualFact } = useTechInsightsApi();
  const alertApi = useApi(alertApiRef);
  const entityRef = useEntity();
  const user = useUserProfile();

  if (!user.backstageIdentity) {
    return null;
  }

  const onSetFactValue = async () => {
    setIsDialogOpen(true);
    handleClose();
  };

  const onManualFactSubmit = async (value: string) => {
    const manualFact: ManualFact = {
      id: `${fact.id}`,
      entity: stringifyEntityRef(entityRef.entity),
      timestamp: new Date().toISOString(),
      isDisabled: false,
      author: {
        ...user.profile,
        userEntityRef: user.backstageIdentity.userEntityRef,
      },
      value,
    };

    const res = await postManualFact(manualFact);
    if (res.status === 200) {
      alertApi.post({
        message: 'Fact updated successfully.',
        severity: 'success',
        display: 'transient',
      });
    } else {
      alertApi.post({
        message:
          'Failed to update the fact.  Please try again or contact the #eng-backstage channel on Slack.',
        severity: 'error',
        display: 'transient',
      });
    }

    setIsDialogOpen(false);
    refreshCheckResults();
  };

  const handleFactEnablement = async () => {
    const manualFact: ManualFact = {
      id: `${fact.id}`,
      entity: stringifyEntityRef(entityRef.entity),
      timestamp: new Date().toISOString(),
      value: 'null',
      isDisabled: !fact.value.isDisabled,
      author: {
        ...user.profile,
        userEntityRef: user.backstageIdentity.userEntityRef,
      },
    };

    const res = await postManualFact(manualFact);
    if (res.status === 200) {
      alertApi.post({
        message: manualFact.isDisabled
          ? 'Check disabled.'
          : 'Check enabled and scheduled for re-evaluation.',
        severity: 'success',
        display: 'transient',
      });
    } else {
      alertApi.post({
        message: `Failed to ${
          manualFact.isDisabled ? 'disable' : 'enable'
        } the check. Please try again or contact the #eng-backstage channel on Slack.`,
        severity: 'error',
        display: 'transient',
      });
    }

    refreshCheckResults();
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        size="small"
        id="more-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon style={{ color: theme.palette.text.hint }} />
      </IconButton>
      <Menu
        className={classes.menu}
        id="menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {fact.metadata?.isManual && (
          <MenuItem key="set-value" onClick={onSetFactValue}>
            <EditIcon />
            Set value
          </MenuItem>
        )}
        <MenuItem key="enablement" onClick={handleFactEnablement}>
          {fact.value.isDisabled ? (
            <>
              <PowerOnIcon />
              Enable
            </>
          ) : (
            <>
              <PowerOffIcon />
              Disable
            </>
          )}
        </MenuItem>
      </Menu>
      <ManualFactDialog
        open={isDialogOpen}
        fact={fact}
        onClose={() => {
          setIsDialogOpen(false);
        }}
        onSubmit={onManualFactSubmit}
      />
    </div>
  );
}

function useTechInsightsApi() {
  const fetchApi = useApi(fetchApiRef);
  const config = useApi(configApiRef);
  const backendUrl = config.getString('backend.baseUrl');

  const postManualFact = async (manualFact: ManualFact) => {
    const res = await fetchApi.fetch(
      `${backendUrl}/api/tech-insights/manual-fact`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(manualFact),
      },
    );

    return res;
  };

  return {
    postManualFact,
  };
}
