import { ServiceMaturityFact } from './types';
import { CheckResult } from '@backstage/plugin-tech-insights-common';

export const getServiceMaturityCheckValue = (checkResults: CheckResult[]) => {
  // if any check is false, the service is not mature
  return !checkResults
    .filter(checkResult => {
      const factIds = Object.keys(checkResult.facts);
      if (factIds.length !== 1) {
        return false;
      }

      const factId = factIds[0];
      const fact = checkResult.facts[factId] as unknown as ServiceMaturityFact;
      return !fact.value.isDisabled;
    })
    .some(({ result }) => !result);
};
