import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { BackstageTheme } from '@backstage/theme';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import {
  makeStyles,
  Typography,
  Button,
  CardHeader,
  Divider,
} from '@material-ui/core';
import { CheckResult } from '@backstage/plugin-tech-insights-common';
import { getServiceMaturityCheckValue } from 'backstage-plugin-service-maturity-common';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface StyleProps {
  isPassed: boolean;
}

const useStyles = makeStyles<BackstageTheme, StyleProps>(
  (theme: BackstageTheme) => ({
    card: {
      '&.MuiCard-root': {
        backgroundColor: theme.palette.background.paper,
      },
    },
    cardHeader: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
    },
    cardContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: ({ isPassed }) =>
        isPassed ? theme.palette.success.main : theme.palette.error.main,
      color: theme.palette.primary.contrastText,
    },
    cardDivider: {
      backgroundColor: theme.palette.divider,
    },
    cardActions: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export const ServiceMaturityCardContent = (props: {
  checkResults: CheckResult[];
}) => {
  const { checkResults } = props;
  const isPassed = getServiceMaturityCheckValue(checkResults);

  const classes = useStyles({ isPassed });
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Card className={classes.card}>
      <CardHeader className={classes.cardHeader} title="Service Maturity" />
      <Divider className={classes.cardDivider} />
      <CardContent className={classes.cardContent}>
        {isPassed ? (
          <Typography variant="h2">Passed</Typography>
        ) : (
          <>
            <Typography variant="h2">Failed</Typography>
          </>
        )}
      </CardContent>
      <Divider className={classes.cardDivider} />
      <CardActions className={classes.cardActions}>
        <Button
          size="small"
          onClick={() => {
            navigate(`${location.pathname}/service-maturity`);
          }}
          style={{
            lineHeight: 1,
          }}
          endIcon={<ArrowForwardIcon />}
        >
          View Details
        </Button>
      </CardActions>
    </Card>
  );
};
