import {
  BackstageTheme,
  createTheme,
  genPageTheme,
  darkTheme,
  shapes,
} from '@backstage/theme';

// Radix UI colors pallette
// https://www.radix-ui.com/colors
export const darkThemeColors = {
  bg1: '#151718',
  bg2primary: '#1A1D1E',
  bg3: '#202425',
  bg4: '#26292B',
  bg5: '#2B2F31',
  bg6: '#313538',
  bg7: '#3A3F42',
  bg8: '#4C5155',
  bg9: '#697177',
  bg10: '#787F85',
  bg11: '#9BA1A6',
  bg12: '#ECEDEE',
  actionPrimary: '#369EFF',
  link: '#2EC8EE',
};

export const customDarkTheme: BackstageTheme = {
  ...createTheme({
    palette: {
      ...darkTheme.palette,
      primary: {
        main: darkThemeColors.actionPrimary,
        contrastText: darkThemeColors.bg12,
      },
      background: {
        default: darkThemeColors.bg2primary,
        paper: darkThemeColors.bg4,
      },
      banner: {
        info: '#0079F2',
        error: '#FF1F1F',
        text: '#343b58',
        link: '#565a6e',
      },
      text: {
        primary: darkThemeColors.bg12,
        secondary: darkThemeColors.bg11,
        hint: darkThemeColors.bg9,
      },
      divider: darkThemeColors.bg5,
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#343b58',
      navigation: {
        background: darkThemeColors.bg2primary,
        indicator: darkThemeColors.bg12,
        color: darkThemeColors.bg11,
        selectedColor: darkThemeColors.bg12,
        navItem: {
          hoverBackground: darkThemeColors.bg3,
        },
        submenu: {
          background: darkThemeColors.bg8,
        },
      },
      tabbar: {
        indicator: darkThemeColors.bg8,
      },
    },
    fontFamily: 'proxima-nova,Arial CE,Arial,sans-serif',
    defaultPageTheme: 'home',
    pageTheme: {
      home: genPageTheme({
        colors: ['#CCE4FC'],
        shape: shapes.square,
        options: { fontColor: darkThemeColors.bg12 },
      }),
    },
  }),
  overrides: {
    ...darkTheme.overrides,
    // These are your custom overrides, either to `material-ui` or Backstage components.
    ...{
      MuiTableCell: {
        root: {
          backgroundColor: darkThemeColors.bg4,
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          color: darkThemeColors.bg7,
          '&$checked': {
            color: darkThemeColors.actionPrimary,
          },
        },
      },
      MuiLink: {
        root: {
          color: darkThemeColors.link,
        },
      },
      MuiTypography: {
        h6: {
          color: darkThemeColors.bg12,
        },
      },
    },
    ...{
      BackstageHeader: {
        header: {
          boxShadow: 'none',
          marginTop: '64px',
        },
      },
      BackstageHeaderLabel: {
        label: {
          color: darkThemeColors.bg11,
          padding: '0 20px',
        },
        value: {
          color: darkThemeColors.bg11,
          padding: '0 20px',
        },
      },
      BackstageSidebarDivider: {
        root: {
          background: darkThemeColors.bg5,
        },
      },
      BackstageItemCardHeader: {
        root: {
          color: darkThemeColors.bg12,
        },
      },
    },
    ...{
      CatalogReactUserListPicker: {
        root: {
          backgroundColor: darkThemeColors.bg4,
        },
      },
    },
  },
};
