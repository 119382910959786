import { ImageList, ImageListItem } from '@material-ui/core';
import React from 'react';
import { IconLink } from './IconLink';
import { IconComponent } from '@backstage/core-plugin-api';

export interface LinksGridListItem {
  href: string;
  text?: string;
  Icon?: IconComponent;
}

interface LinksGridListProps {
  items: LinksGridListItem[];
}

export function LinksGridList(props: LinksGridListProps) {
  const { items } = props;

  return (
    <ImageList rowHeight="auto" gap={20}>
      {items.map(({ text, href, Icon }, i) => (
        <ImageListItem
          key={i}
          style={{
            maxWidth: 'fit-content',
          }}
        >
          <IconLink href={href} text={text ?? href} Icon={Icon} />
        </ImageListItem>
      ))}
    </ImageList>
  );
}
