import React from 'react';
import { MarkdownContent } from '@backstage/core-components';
import { styled } from '@material-ui/core';

const Box = styled('div')(({ theme }) => ({
  paddingTop: '12px',
  paddingLeft: '72px',
  paddingRight: '32px',
  color: theme.palette.text.secondary,
}));

const Title = styled('span')({
  fontSize: '1rem',
});

export const ServiceMaturityCheckGuidance = ({
  title = 'How to fix?',
  guidance,
}: {
  title?: string;
  guidance: string;
}) => {
  return (
    <Box>
      <Title>{title}</Title>
      <MarkdownContent content={guidance} />
    </Box>
  );
};
