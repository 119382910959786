/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import LogoDark from '../../assets/logo-dark.png';
import LogoLight from '../../assets/logo-light.png';
import PrideLogoDark from '../../assets/pride-logo-dark.png';
import { useTheme } from '@material-ui/core/styles';
import { BackstageTheme } from '@backstage/theme';

const useStyles = makeStyles({
  container: {
    borderRadius: '2px',
    display: 'flex',
    padding: '4px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
});

const LogoFull = () => {
  const { palette } = useTheme<BackstageTheme>();
  const classes = useStyles();
  const isPrideMonth = new Date().getMonth() === 5;
  const darkLogo = isPrideMonth ? PrideLogoDark : LogoDark;
  const logo = palette.type === 'dark' ? darkLogo : LogoLight;

  return (
    <div className={classes.container}>
      <img src={logo} alt="Productboard" height={20} />
    </div>
  );
};

export default LogoFull;
