import React from 'react';
import { Route } from 'react-router';
import { apiDocsPlugin } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
  SignInProviderConfig,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { PermissionedRoute } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  discoveryApiRef,
  IdentityApi,
  oktaAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { themes } from './themes';
import ChatIcon from '@material-ui/icons/Chat';
import {
  SiSentry,
  SiSlack,
  SiOpsgenie,
  SiJira,
  SiDatadog,
  SiGrafana,
  SiAmazonaws,
} from 'react-icons/si';
import { GoGitPullRequest, GoMarkGithub, GoRepo, GoBook } from 'react-icons/go';
import { setTokenCookie } from './utils/jwt';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import { RootlyPage } from '@rootly/backstage-plugin';
import * as plugins from './plugins';
import { OpsgeniePage } from './components/plugins/opsgenie_page';

import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { ApiExplorerPage } from './components/catalog/ApiExplorerPage';
import { CustomCatalogPage } from './components/catalog/CatalogPage';
import { CustomTechDocsHome } from './components/catalog/TechDocsPage';

const oktaProvider: SignInProviderConfig = {
  id: 'okta-auth-provider',
  title: 'Okta',
  message: 'Sign in with Okta',
  apiRef: oktaAuthApiRef,
};

const app = createApp({
  apis,
  themes,
  icons: {
    chat: ChatIcon,
    sentry: SiSentry,
    slack: SiSlack,
    github: GoMarkGithub,
    opsgenie: SiOpsgenie,
    repo: GoRepo,
    pull_request: GoGitPullRequest,
    runbook: GoBook,
    datadog: SiDatadog,
    grafana: SiGrafana,
    jira: SiJira,
    aws: SiAmazonaws,
  },
  plugins: Object.values(plugins),
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          providers={[oktaProvider]}
          align="center"
          onSignInSuccess={async (identityApi: IdentityApi) => {
            await setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );
            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <CustomCatalogPage />
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <CustomTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <Mermaid />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Service Templates',
              filter: entity =>
                entity?.metadata.name === 'kotlin-service-template' ?? false,
            },
            {
              title: 'Documentation',
              filter: entity => entity.metadata.tags?.includes('docs') ?? false,
            },
            {
              title: 'Utilities',
              filter: entity =>
                entity.metadata.tags?.includes('utilities') ?? false,
            },
          ]}
        />
      }
    />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={
        <iframe
          title="Productboard Technology Radar"
          style={{
            marginTop: '88px',
            padding: '0px 24px',
            height: '100vh',
            width: '100%',
            border: 'none',
          }}
          src="https://technology-radar.productboard.net"
        />
      }
    />
    <Route path="/opsgenie" element={<OpsgeniePage />} />
    <Route path="/rootly" element={<RootlyPage />} />
    <PermissionedRoute
      path="/catalog-import"
      permission={catalogEntityCreatePermission}
      element={<CatalogImportPage />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route
      path="/catalog-graph"
      element={
        <CatalogGraphPage
          initialState={{
            maxDepth: 1,
            selectedKinds: [
              'api',
              'component',
              'system',
              'resource',
              'template',
              'group',
            ],
          }}
        />
      }
    />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay transientTimeoutMs={2000} />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
