/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import TrafficIcon from '@material-ui/icons/Traffic';
import LogoFull from './LogoFull';
import { NavLink } from 'react-router-dom';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import ViewListIcon from '@material-ui/icons/ViewList';
import NucleusSvg from '../../assets/nucleus.svg';
import { SiGraphql, SiOpenapiinitiative, SiOpsgenie, SiInternetarchive } from 'react-icons/si';
import { SearchBar } from '../home/SearchBar';
import { ClockConfig, HeaderWorldClock } from '@backstage/plugin-home';
import { darkThemeColors } from '../../themes/dark';
import { BackstageTheme } from '@backstage/theme';

const NucleusIcon = () => {
  return <img src={NucleusSvg} alt="Nucleus" width={20} height={20} />;
};

const GraphQlIcon = () => {
  return <SiGraphql size={20} />;
};

const OpsgenieIcon = () => {
  return <SiOpsgenie size={20} />;
};

const ArchitectureIcon = () => {
  return <SiInternetarchive size={20} />;
};

const ApiIcon = () => {
  return <SiOpenapiinitiative size={20} />
};

const DeploymentDashboardIcon = () => {
  return <TrafficIcon />;
};

const topBarStyles = makeStyles<BackstageTheme>(theme => ({
  root: {
    width: '100%',
    height: 2 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.type === 'dark' ? darkThemeColors.bg3 : '#fff',
    borderBottom:
      theme.palette.type === 'dark'
        ? `1px solid ${darkThemeColors.bg4}`
        : `1px solid ${theme.palette.divider}`,
    position: 'fixed',
    zIndex: 9999,
    left: 0,
  },
  link: {
    width: sidebarConfig.drawerWidthOpen,
    marginLeft: 24,
  },
  clock: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
}));

const HeaderLogo = () => {
  const classes = topBarStyles();

  return (
    <Link component={NavLink} to="/" underline="none" className={classes.link}>
      <LogoFull />
    </Link>
  );
};

const clockConfigs: ClockConfig[] = [
  {
    label: 'SF',
    timeZone: 'America/Los_Angeles',
  },

  {
    label: 'VC',
    timeZone: 'America/Vancouver',
  },
  {
    label: 'PRG',
    timeZone: 'Europe/Prague',
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

const ContentHeader = () => {
  const classes = topBarStyles();

  return (
    <div className={classes.root}>
      <HeaderLogo />
      <SearchBar />
      <div className={classes.clock}>
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
      </div>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <div style={{ height: '88px' }} /> {/* Logo spacer */}
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarItem icon={ViewListIcon} to="catalog" text="Catalog" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem
            icon={ArchitectureIcon}
            to="catalog/default/component/pb-architecture/docs"
            text="Architecture"
          />
          <SidebarItem
            icon={ApiIcon}
            to="catalog/default/component/pb-architecture/docs/public-api-guidelines/"
            text="API Guidelines"
          />
          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
          <SidebarItem
            icon={DeploymentDashboardIcon}
            to="/catalog/default/component/pb-frontend/deployments-dashboard/prod"
            text="FE deploy"
          />
          <SidebarItem
            icon={NucleusIcon}
            text="Nucleus"
            onClick={() =>
              window.open('https://nucleus.productboard.net/', '_blank')
            }
          />
          <SidebarItem
            icon={GraphQlIcon}
            text="Hive"
            onClick={() =>
              window.open('https://app.graphql-hive.com/productboard/productboard', '_blank')
            }
          />
          <SidebarItem icon={OpsgenieIcon} to="opsgenie" text="OpsGenie" />
          <SidebarItem icon={ExtensionIcon} to="rootly" text="Rootly" />
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    <ContentHeader />
    {children}
  </SidebarPage>
);
