import { InfoCard, MarkdownContent } from '@backstage/core-components';
import { LinearProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react';

type HighlightsDoc = {
  title: string;
  subtitle: string;
  content: string;
};

export const PlatformHighlightsCard = () => {
  const [doc, setDoc] = useState<HighlightsDoc | undefined>(undefined);
  // Fetch the platform highlights from the markdown file
  useEffect(() => {
    async function getDoc() {
      const highlightsPath = require('../../assets/platform-highlights.md');
      const text = await fetch(highlightsPath).then(r => r.text());

      const [metadata, content] = text.split('---').slice(0, 2);
      const [title, subtitle] = metadata.split('\n').slice(0, 2);

      setDoc({ title, subtitle, content });
    }

    if (!doc) {
      getDoc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !doc ? (
    <LinearProgress />
  ) : (
    <InfoCard title={doc.title} subheader={doc.subtitle}>
      <MarkdownContent content={doc.content} />
    </InfoCard>
  );
};
