import React from 'react';
import { useTheme } from '@material-ui/core';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import { CheckResult } from '@backstage/plugin-tech-insights-common';
import { Link } from '@backstage/core-components';
import { parseEntityRef } from '@backstage/catalog-model';
import { ServiceMaturityFact } from 'backstage-plugin-service-maturity-common';
import { FactActionsMenu } from '../FactActionsMenu/FactActionsMenu';
import { ServiceMaturityCheckGuidance } from '../ServiceMaturityCheckGuidance';

import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const ServiceMaturityCategoryListItem = ({
  result,
}: {
  result: CheckResult;
}) => {
  const theme = useTheme();

  const factIds = Object.keys(result.facts);
  if (factIds.length === 0) {
    return null;
  }

  // For now we only support one fact per check
  const factId = factIds[0];
  const fact = {
    ...result.facts[factId],
    id: factId,
  } as unknown as ServiceMaturityFact;
  const isManual = fact.metadata?.isManual ?? false;
  const isDisabled = fact.value.isDisabled;

  return (
    <List>
      <ListItem key={factId}>
        <ListItemIcon style={{ padding: '0 16px' }}>
          {isDisabled ? (
            <Tooltip title="Disabled">
              <PowerOffIcon sx={{ color: theme.palette.text.disabled }} />
            </Tooltip>
          ) : (
            <>
              {result.result ? (
                <Tooltip title="Pass">
                  <CheckCircleIcon color="success" />
                </Tooltip>
              ) : (
                <Tooltip title="Fail">
                  <ErrorIcon color="error" />
                </Tooltip>
              )}
            </>
          )}
        </ListItemIcon>
        <ListItemText
          style={{ paddingRight: '16px' }}
          primary={
            <CheckTitle
              title={result.check.name}
              icon={
                isManual && (
                  <Tooltip title="Manual check">
                    <ManageAccountsIcon
                      sx={{
                        marginLeft: '8px',
                        fontSize: '1.25rem',
                        color: theme.palette.info.main,
                      }}
                    />
                  </Tooltip>
                )
              }
            />
          }
          primaryTypographyProps={{
            sx: {
              color: isDisabled
                ? theme.palette.text.disabled
                : theme.palette.text.primary,
            },
          }}
          secondary={result.check.description}
          secondaryTypographyProps={{
            sx: {
              color: isDisabled
                ? theme.palette.text.disabled
                : theme.palette.text.secondary,
            },
          }}
        />
        <FactMetadata fact={fact} />
      </ListItem>
      {!isDisabled && result.check.metadata?.guidance && (
        <ServiceMaturityCheckGuidance
          guidance={result.check.metadata.guidance}
        />
      )}
    </List>
  );
};

function FactMetadata({ fact }: { fact: ServiceMaturityFact }) {
  const theme = useTheme();
  const isManual = fact.metadata?.isManual ?? false;
  const isDisabled = fact.value.isDisabled;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: theme.palette.text.hint,
        fontSize: '0.8rem',
      }}
    >
      {typeof fact.value.value !== 'boolean' && fact.value.value}
      <div
        style={{
          display: 'flex',
          alignItems: 'end',
          flexDirection: 'column',
          marginLeft: '8px',
          gap: '4px',
          color: theme.palette.text.hint,
        }}
      >
        {(isManual || isDisabled) && fact.value.author && (
          <>
            <span
              style={{
                display: 'flex',
                gap: '4px',
              }}
            >
              {`${isDisabled ? 'Disabled by ' : 'Updated by '}`}
              <Link
                to={`/catalog/default/user/${
                  parseEntityRef(fact.value.author.userEntityRef).name
                }`}
              >
                {fact.value.author.displayName}
              </Link>
            </span>

            {fact.value.timestamp
              ? `${new Date(fact.value.timestamp).toDateString()}`
              : null}
          </>
        )}
      </div>

      <FactActionsMenu fact={fact} />
    </div>
  );
}

function CheckTitle({
  title,
  icon,
}: {
  title: string;
  icon?: React.ReactNode;
}) {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {title}
      {icon}
    </div>
  );
}
