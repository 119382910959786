import React from 'react';

import {
  Content,
  ContentHeader,
  SupportButton,
} from '@backstage/core-components';
import {
  CatalogFilterLayout,
  EntityOwnerPicker,
  EntityTagPicker,
  UserListPicker,
  EntityListProvider,
} from '@backstage/plugin-catalog-react';
import {
  EntityListDocsTable,
  TechDocsPageWrapper,
  TechDocsPicker,
} from '@backstage/plugin-techdocs';

export const CustomTechDocsHome = () => {
  return (
    <TechDocsPageWrapper>
      <Content>
        <ContentHeader title="">
          <SupportButton />
        </ContentHeader>
        <EntityListProvider>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <TechDocsPicker />
              <UserListPicker initialFilter="all" />
              <EntityOwnerPicker />
              <EntityTagPicker />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <EntityListDocsTable />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </EntityListProvider>
      </Content>
    </TechDocsPageWrapper>
  );
};
