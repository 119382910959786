import React from 'react';

import { AppTheme } from '@backstage/core-plugin-api';

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import LightModeIcon from '@material-ui/icons/WbSunny';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { productboardLightTheme } from './productBoardLight';
import { customDarkTheme } from './dark';

interface ProviderCallback {
  children: React.ReactNode;
}

export const themes: (Partial<AppTheme> & Omit<AppTheme, 'theme'>)[] = [
  {
    id: 'productboard-light-theme',
    title: 'Light',
    variant: 'light' as 'light',
    icon: <LightModeIcon />,
    Provider: ({ children }: ProviderCallback) => (
      <ThemeProvider theme={productboardLightTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  },
  {
    id: 'dark-theme',
    title: 'Dark',
    variant: 'dark' as 'dark',
    icon: <DarkModeIcon />,
    Provider: ({ children }: ProviderCallback) => (
      <ThemeProvider theme={customDarkTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    ),
  },
];
