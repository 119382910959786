import {
  Entity,
  parseEntityRef,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
} from '@backstage/catalog-model';

const isApisAvailable = (entity: Entity): boolean => {
  const relations = entity.relations
    ?.map(r => ({ type: r.type, target: parseEntityRef(r.targetRef) }))
    .filter(
      r =>
        r.type.toLocaleLowerCase('en-US') === RELATION_HAS_PART &&
        r.target.kind === 'api',
    );

  return !relations ? false : relations.length > 0;
};

const isLinksAvailable = (entity: Entity): boolean => {
  return !entity.metadata.links ? false : entity.metadata.links.length > 0;
};

const isSubcomponentsAvailable = (entity: Entity): boolean => {
  const relations = entity.relations
    ?.map(r => ({ type: r.type, target: parseEntityRef(r.targetRef) }))
    .filter(r => r.type === RELATION_HAS_PART && r.target.kind === 'component');

  return !relations ? false : relations.length > 0;
};

const isDependenciesAvailable = (entity: Entity): boolean => {
  const relations = entity.relations?.filter(
    r => r.type === RELATION_DEPENDS_ON,
  );

  return !relations ? false : relations.length > 0;
};

const isServiceMaturityAvailable = (entity: Entity): boolean => {
  return (
    entity.spec?.lifecycle === 'production' &&
    (entity.spec?.type === 'service' || entity.spec?.type === 'website')
  );
};

export {
  isApisAvailable,
  isLinksAvailable,
  isSubcomponentsAvailable,
  isDependenciesAvailable,
  isServiceMaturityAvailable,
};
