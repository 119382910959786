import React from 'react';
import { BackstageTheme } from '@backstage/theme';
import { CheckResult } from '@backstage/plugin-tech-insights-common';
import { ButtonBase, ListSubheader, makeStyles } from '@material-ui/core';
import { capitalize } from 'lodash';
import { ServiceMaturityFact } from 'backstage-plugin-service-maturity-common';

import ExpandMore from '@mui/icons-material/ExpandMore';
import CancelIcon from '@mui/icons-material/Cancel';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyles = makeStyles<BackstageTheme>((theme: BackstageTheme) => ({
  listSubheader: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    fontSize: '1.25rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  categoryCheckResults: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '4px',
    fontSize: '0.875rem',
    lineHeight: '1',
  },
  collapsibleCategoryTitle: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type ServiceMaturityCategoryListSubheaderProps = {
  category: string;
  isOpen: boolean;
  onClick: () => void;
  results: CheckResult[];
};

export const ServiceMaturityCategoryListSubheader = ({
  category,
  isOpen,
  onClick,
  results,
}: ServiceMaturityCategoryListSubheaderProps) => {
  const classes = useStyles();

  return (
    <ButtonBase style={{ width: '100%' }} onClick={onClick}>
      <ListSubheader
        component="div"
        id={`${category}-list-subheader`}
        className={classes.listSubheader}
      >
        <CollapsibleCategoryTitle isOpen={isOpen} category={category} />
        {!isOpen ? <CheckResultsOverview checkResults={results} /> : null}
      </ListSubheader>
    </ButtonBase>
  );
};

function CollapsibleCategoryTitle({
  isOpen,
  category,
}: {
  isOpen: boolean;
  category: string;
}) {
  const classes = useStyles();

  return (
    <div className={classes.collapsibleCategoryTitle}>
      {isOpen ? <ExpandMore /> : <ChevronRightIcon />}
      {capitalize(category.replace('-', ' '))}
    </div>
  );
}

function CheckResultsOverview({
  checkResults,
}: {
  checkResults: CheckResult[];
}) {
  const totalChecks = checkResults.filter(checkResult => {
    const factIds = Object.keys(checkResult.facts);
    if (factIds.length === 0) {
      return false;
    }

    const factId = factIds[0];
    const fact = checkResult.facts[factId] as unknown as ServiceMaturityFact;
    return !fact.value.isDisabled;
  });

  const passedChecks = totalChecks.filter(({ result }) => result);

  const isPassed = passedChecks.length === totalChecks.length;
  const isDisabled = totalChecks.length === 0;

  const classes = useStyles();

  return (
    <div className={classes.categoryCheckResults}>
      {!isDisabled ? `${passedChecks.length} / ${totalChecks.length}` : null}
      {isPassed ? (
        <CheckCircleIcon color={isDisabled ? 'disabled' : 'success'} />
      ) : (
        <CancelIcon color="error" />
      )}
    </div>
  );
}
