export function isDev() {
  return process.env.NODE_ENV === 'development';
}

export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

export function getBuildInfo() {
  // https://backstage.io/docs/local-dev/cli-build-system#frontend-production
  return (process.env.BUILD_INFO ?? {
    cliVersion: 'dev',
    gitVersion: 'dev',
    packageVersion: 'dev',
    timestamp: new Date().getTime(),
    commit: 'dev',
  }) as unknown as {
    cliVersion: string; // The version of the CLI package
    gitVersion: string; // output of `git describe --always`
    packageVersion: string; // The version of the app package itself
    timestamp: number; // Date.now() when the build started
    commit: string; // output of `git rev-parse HEAD`
  };
}
