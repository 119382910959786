import { Entity } from '@backstage/catalog-model';
import { InfoCard, CardTab, TabbedCard } from '@backstage/core-components';
import {
  useApi,
  identityApiRef,
  BackstageUserIdentity,
} from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { List, ListItemText, Divider, LinearProgress } from '@material-ui/core';
import { ListItemButton } from '@mui/material';
import { groupBy } from 'lodash';
import React, { useEffect, useState } from 'react';

type OwnedEntitiesMap = Map<string, Entity[]>;

const OwnedEntitiesList = ({ entities }: { entities: Entity[] }) => {
  return (
    <List dense>
      {entities.map((entity, index) => (
        <div key={entity.metadata.name}>
          <ListItemButton
            href={`/catalog/${
              entity.metadata.namespace ?? 'default'
            }/${entity.kind.toLowerCase()}/${entity.metadata.name}`}
          >
            <ListItemText
              primary={entity.metadata.name}
              secondary={entity.kind === 'Resource' ? entity.spec?.type : null}
            />
          </ListItemButton>
          {index !== entities.length - 1 && <Divider />}
        </div>
      ))}
    </List>
  );
};

export const OwnedEntitiesCard = () => {
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);

  const [userEntityRef, setUserEntityRef] = useState<string | undefined>(
    undefined,
  );
  const [userOwnershipEntityRefs, setUserOwnershipEntityRefs] = useState<
    string[] | undefined
  >(undefined);
  const [ownedEntities, setOwnedEntities] = useState<OwnedEntitiesMap>();

  useEffect(() => {
    const getUserData = async () => {
      const entityRef = await identityApi
        .getBackstageIdentity()
        .then((identity: BackstageUserIdentity) => identity.userEntityRef);

      const ownershipEntityRefs = await identityApi
        .getBackstageIdentity()
        .then(
          (identity: BackstageUserIdentity) => identity.ownershipEntityRefs,
        );

      setUserEntityRef(entityRef);
      setUserOwnershipEntityRefs(ownershipEntityRefs);
    };

    getUserData();
  }, [identityApi]);

  useEffect(() => {
    if (userEntityRef && userOwnershipEntityRefs) {
      const getEntities = async () => {
        const filteredEntities = await catalogApi
          .getEntities()
          .then(response => {
            return response.items.filter(
              entity =>
                entity.kind === 'Component' ||
                entity.kind === 'API' ||
                entity.kind === 'Resource',
            );
          })
          .then(entities => {
            return entities.filter(entity => {
              const entityOwner = entity.relations?.find(
                relation => relation.type === 'ownedBy',
              )?.targetRef;

              // ignore engineering group
              if (entityOwner === 'group:default/engineering') {
                return false;
              }

              return entityOwner
                ? userOwnershipEntityRefs.includes(entityOwner)
                : false;
            });
          });

        const groups = groupBy(filteredEntities, entity => entity.spec?.type);

        const result = new Map<string, Entity[]>();
        Object.keys(groups).forEach(key => {
          switch (key) {
            case 'service':
            case 'website':
              result.set(
                'Services',
                result.get('Services')?.concat(groups[key]) || groups[key],
              );
              break;
            case 'rds-psql':
            case 's3':
            case 'elasticsearch':
            case 'messaging':
            case 'elasticache-redis':
              result.set(
                'Resources',
                result.get('Resources')?.concat(groups[key]) || groups[key],
              );
              break;
            case 'openapi':
            case 'asyncapi':
            case 'apollographql':
              result.set(
                'APIs',
                result.get('APIs')?.concat(groups[key]) || groups[key],
              );
              break;
            case 'documentation':
              result.set('Docs', groups[key]);
              break;
            default:
              result.set(
                'Other',
                result.get('Other')?.concat(groups[key]) || groups[key],
              );
              break;
          }
        });

        setOwnedEntities(result);
      };

      getEntities();
    }
  }, [catalogApi, userEntityRef, userOwnershipEntityRefs]);

  if (!ownedEntities) {
    return <LinearProgress />;
  }

  return ownedEntities.size === 0 ? (
    <InfoCard title="Owned Entities">
      It seems that your team doesn't own any entities. If you think this is a
      mistake, please contact{' '}
      <a
        className="MuiLink-root"
        href="https://productboard.slack.com/archives/C01AG3ZQG1F"
      >
        #eng-backstage
      </a>{' '}
      on Slack.
    </InfoCard>
  ) : (
    <TabbedCard title="Owned Entities">
      {Array.from(ownedEntities.entries()).map(([key, entities]) => (
        <CardTab key={key} label={`${key} (${entities.length})`}>
          <OwnedEntitiesList entities={entities} />
        </CardTab>
      ))}
    </TabbedCard>
  );
};
